<template>
  <div class="softOutfit">
    <header-nav></header-nav>
    <b-container class="softOutfit-cont" fluid>
      <b-row class="classification">
        <b-col class="classification-left" sm="4" md="2" lg="2" xl="1">
          <div class="classification-left-title">
            <div class="category-name">类别</div>
            <div :class="type_id == ''?'category-all':'category-all-active'" @click="classificationItem('', '')">全部</div>
          </div>
        </b-col>
        <b-col class="classification-right" sm="8" md="10" lg="10" xl="11">
          <b-row class="classification-right-cont">
            <b-col
              class="classification-right-item"
              @click="classificationItem(index, item)"
              v-for="(item, index) in classificationList"
              :key="index"
              sm="6"
              md="3"
              lg="3"
              xl="2"
            >
              <img
                :src="
                  require(`@/../public/icon/mall/softOutfit/${item.imgUrl}`)
                "
                alt=""
              />
              <span>{{ item.name }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="style">
        <b-col class="style-title" sm="4" md="2" lg="2" xl="1"> 风格 </b-col>
        <b-col
          @click="chooseStyle(item)"  
          :class="style_id == item.id ? 'style-item-active' : 'style-item'"
          sm="4"
          md="2"
          lg="2"
          xl="1"
          v-for="(item, index) in styleList"
          :key="index"
        >
          {{ item.name }}
        </b-col>
      </b-row>
      <b-row class="brand-cont">
        <b-col
          class="brand-item"
          v-for="(item, index) in brandList"
          :key="index"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <div class="brand-in" @click="toBrandIn(item)">
            <img
              class="brand-img"
              :src="item.brand_cover"
              alt=""
            />
            <div class="brand-detail">
              <img
                :src="item.logo"
                alt=""
              />
              <span>{{item.brand_miaoshu}}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <right></right>
    <footer-nav></footer-nav>
  </div>
</template>
<script>
  import headerNav from "@/components/Navbar";
  import Right from "@/components/Right.vue";
  import footerNav from "@/components/Footer";
  import dump from "@/util/dump";
export default {
  components: {
    headerNav,
    Right,
    footerNav
  },
  data() {
    return {
      classificationList: [
        //分类数据
        { name: "创意灯具", imgUrl: "cydj.jpg", id: "1" },
        { name: "窗帘布艺", imgUrl: "clby.jpg", id: "2" },
        { name: "地毯挂毯", imgUrl: "dtgt.jpg", id: "3" },
        { name: "墙饰画艺", imgUrl: "qshy.jpg", id: "4" },
        { name: "生活日用", imgUrl: "shry.jpg", id: "5" },
        { name: "家纺", imgUrl: "jf.jpg", id: "6" },
        { name: "摆件", imgUrl: "bj.jpg", id: "7" },
        { name: "花艺", imgUrl: "hy.jpg", id: "8" },
        { name: "雕塑", imgUrl: "ds.jpg", id: "9" },
        { name: "其他", imgUrl: "qt.jpg", id: "10" }
      ],
      styleList: [
        //风格数据
        {
          name: "全部",
          id: "",
        },
        {
          name: "极简",
          id: "16",
        },
        {
          name: "轻奢",
          id: "15",
        },
        {
          name: "中式",
          id: "53",
        },
        {
          name: "美式",
          id: "52",
        },
        {
          name: "北欧/现代",
          id: "54",
        },
        {
          name: "英式",
          id: "55",
        },
        {
          name: "欧式/新古典",
          id: "56",
        },
        {
          name: "其他",
          id: "645",
        },
      ],
      brandList:[],//品牌列表
      type_id: "", //类型id
      style_id: "", //风格id
      typeIndexDef:-1
    };
  },
  mounted() {
    this.getbrands();
  },
  methods: {
    //获取品牌数据
    getbrands() {
      const that = this;
      let para = {
        type_id: that.type_id,
        style_id: that.style_id,
      };
      that.$api.getbrands(para).then((res) => {
        if (res.status == 100) {
            that.brandList = res.data.brands
        }
      });
    },
    //选择风格
    chooseStyle(item) {
      const that = this;
      that.style_id = item.id;
      that.getbrands();
    },
    classificationItem(index,item) {
      const that = this;
      if (item.id) {
        if (that.typeIndexDef != index) {
          if (that.typeIndexDef != -1) {
            let imgDef = that.classificationList[that.typeIndexDef].imgUrl.replace(
              "-check.",
              "."
            );
            that.$set(that.classificationList[that.typeIndexDef], "imgUrl", imgDef);
          }
          item.imgUrl = item.imgUrl.replace(".", "-check.");
          that.$set(that.classificationList[index], "imgUrl", item.imgUrl);
          that.type_id = item.id;
          that.typeIndexDef = index;
          that.getbrands();
        }
      } else {
        if (that.typeIndexDef != -1) {
          let imgDef = that.classificationList[that.typeIndexDef].imgUrl.replace(
            "-check.",
            "."
          );
          that.$set(that.classificationList[that.typeIndexDef], "imgUrl", imgDef);
          that.type_id = "";
          that.typeIndexDef = -1;
          that.getbrands();
        }
      }
    },
    categoryAll() {},
    toBrandIn(obj){
      console.log(obj)
      dump.link({
        type: 3,
        link: "/brandDetails",
        params: {
          brand_id: obj.brand_id,
          brand_type: obj.brand_type
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/view/mall/softOutfit/softOutfit";
</style>